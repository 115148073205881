import React from "react";
import Layout from "../components/layout";
import ContactUs from '../components/contact-us';
import "../css/contactHero.css";

import { Helmet } from 'react-helmet';





const ContactPage = () => (
        <Layout>
             <Helmet title={'Contact Page'} />
            <ContactUs />
        </Layout>
)

export default ContactPage